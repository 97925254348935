import * as React from 'react'

import { TheCarousel } from '../components/general/the-carousel'
import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'
import ExpMoviesSection from '@/components/top/exp-movies-section'
import KvSection from '@/components/top/kv-section'
import { LetterSection } from '@/components/top/letter-section'

const IndexPage = () => (
  <Layout>
    <Seo />
    <KvSection />
    <TheCarousel />
    <LetterSection />
    <ExpMoviesSection />
  </Layout>
)

export default IndexPage
