import clsx from 'clsx'
import * as React from 'react'
import styles from './letter-section.module.scss'
import SectionTitle from './section-title'
import Border from '@/images/border-dot.svg'
import iconBooks from '@/images/icon-books.svg'
import linkOutIcon from '@/images/icon-link-out.svg'
import readMoreIcon from '@/images/icon-more.svg'
import iconReports from '@/images/icon-report.svg'
import letterStamp from '@/images/letter-stamp.svg'
import newTag from '@/images/tag-new.svg'

type Book = {
  title: string
  author: string
  publish: string
  description: string
  moreLink: string
  editor?: string
  isNew?: boolean
}

type Document = {
  title: string
  subTitle: string
  moreLink: string
}

const books = [
  {
    title: '精神疾患と認知機能―最近の進歩―',
    editor: '精神疾患と認知機能研究会',
    publish: '新興医学出版社',
    description:
      '認知機能から見た統合失調症ならびに気分障害についての重要な知見がまとめられた一冊です',
    moreLink: 'http://shinkoh-igaku.jp/mokuroku/data/728.html',
    isNew: true,
  },
  {
    title: '精神科 作業療法士の仕事',
    author: '関　京子',
    publish: '新興医学出版社',
    description:
      '作業療法士として長く精神疾患患者と接してきた著者による、精神科における効果的な作業療法の進め方が解説されています',
    moreLink: 'https://www.kyodo-isho.co.jp/book/b10025851.html',
    isNew: true,
  },
  {
    title: '現代の認知心理学４◆注意と安全【電子書籍版】',
    author: '認知心理学会、原田悦子、篠原一光　他',
    publish: '北大路書房',
    description:
      '認知機能の中で「注意」にフォーカスし、そのメカニズムや役割等に関する様々な研究が紹介されています',
    moreLink: 'https://www.kitaohji.com/book/b581439.html',
    isNew: true,
  },
  {
    title:
      '精神科臨床とリカバリー支援のための認知リハビリテーション 統合失調症を中心に',
    author: '松井 三枝',
    publish: '北大路書房',
    description:
      '認知矯正療法やメタ認知トレーニングの他、認知リハビリテーションの各種技法を紹介をしています',
    moreLink: 'https://www.kitaohji.com/book/b581667.html',
  },
  {
    title: '社会認知ならびに対人関係のトレーニング(SCIT) 治療マニュアル',
    author: 'デイビッド・ロバーツ／デイビッド・ペン／デニス・コームズ',
    publish: '星和書店',
    description:
      '付録としてセッション・ホームワーク用の配布資料の他、DVD とCD-ROM も同封。すぐにでも治療現場で使える実践的な1 冊です',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo05/bn739.html',
  },
  {
    title: 'メタ認知トレーニングをはじめよう! MCTガイドブック',
    author: '石垣 琢麿',
    publish: '星和書店',
    description:
      '「基礎編」と「実践編」に分け、一層寄り添った支援が可能となる「MCT」を一から学ぶ入門書となる1冊です',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo05/bn1043.html',
  },
  {
    title: 'うつ病のためのメタ認知トレーニング（D-MCT）',
    author: 'レナ・イェリネク／マリット・ハウシルト／シュテフェン・モリッツ',
    publish: '金子書房',
    description:
      'うつ病に典型的な認知バイアスに焦点をあて8つのモジュールで構成された、トレーニングのためのマニュアルです',
    moreLink: 'https://www.kanekoshobo.co.jp/book/b383218.html',
  },
  {
    title: '統合失調症の認知機能改善療法',
    author: 'ティル・ワイクス／クレア・リーダー',
    publish: '金剛出版',
    description:
      'メンタルヘルス・サービス従業者向けの認知機能改善のための理論モデルと臨床の原則を提示した書籍です',
    moreLink: 'https://www.kongoshuppan.co.jp/book/b514634.html',
  },
  {
    title: '「精神疾患における認知機能障害の矯正法」臨床家マニュアル',
    author: 'アリス・メダリア／ナディン・レヴハイム／ティファニー・ハーランズ',
    publish: '星和書店',
    description:
      '様々なケースや具体的な場面をもとに理論の実践方法や、幅広い精神科臨床に役立つコツがふんだんに取り入れられています',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo05/bn643.html',
  },
  {
    title: '「精神疾患における認知機能障害の矯正法」臨床家マニュアル 第2版',
    author:
      'アリス・メダリア／ティファニー・ハーランズ／アリス・サバースタイン／ナディン・レヴハイム',
    publish: '星和書店',
    description:
      '第１版を大幅に改訂し、認知矯正療法「ＮＥＡＲ」の実践に必要なすべての情報を網羅した決定版となる書籍です',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo05/bn987.html',
  },
  {
    title: '統合失調症の認知機能ハンドブック-生活機能改善のために',
    author: '髙橋 太郎',
    publish: '南江堂',
    description:
      '統合失調症の治療やリハビリに対して必要な基本的事柄と薬物治療に関する情報を盛り込んだ医療関係者から患者・家族まで幅広い読者を対象に簡潔でわかりやすく解説されています',
    moreLink:
      'https://www.amazon.co.jp/%E7%B5%B1%E5%90%88%E5%A4%B1%E8%AA%BF%E7%97%87%E3%81%AE%E8%AA%8D%E7%9F%A5%E6%A9%9F%E8%83%BD%E3%83%8F%E3%83%B3%E3%83%89%E3%83%96%E3%83%83%E3%82%AF%E2%80%95%E7%94%9F%E6%B4%BB%E6%A9%9F%E8%83%BD%E3%81%AE%E6%94%B9%E5%96%84%E3%81%AE%E3%81%9F%E3%82%81%E3%81%AB-Philip-D-Harvey/dp/4524238336',
  },
  {
    title: '統合失調症患者と家族が選ぶ社会復帰をめざす認知矯正療法',
    author: '中坪 太久郎',
    publish: '幻冬舎メディアコンサルティング',
    description:
      '統合失調症の患者さんの社会復帰を後押しする、認知矯正療法の詳細について実際の治療の様子や治療の効果を高めるメンタルケアや患者さんとの接し方などについて解説されています',
    moreLink: 'https://www.hanmoto.com/bd/isbn/9784344911963',
  },
  {
    title: '統合失調症への臨床心理学支援',
    author: '中坪　太久郎',
    publish: 'ミネルヴァ書房',
    description:
      '「認知機能障害」と「家族支援」をキーワードに、統合失調症を対象とした支援に携わる心理職や、医療関係者などに広く役立ててもらえる内容の書籍です',
    moreLink: 'https://www.minervashobo.co.jp/book/b103293.html',
  },
]

const documents = [
  {
    title: '精神科治療学 第30巻11号',
    subTitle: '【特集】 日常診療に活かす認知機能障害の病態・評価・治療',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo01/bo0102/bn/30/11index.html',
  },
  {
    title: '精神科治療学 第30巻09号',
    subTitle:
      '【特集】 治療を進める上での病識、病感・双極性障害の病識と認知機能の障害',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo01/bo0102/bn/30/09index.html',
  },
  {
    title: '臨床精神薬理 第18巻12号',
    subTitle: '《今月の特集：統合失調症の認知機能障害に対する治療戦略最前線》',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo01/bo0103/bn/18/12index.html',
  },
  {
    title: '臨床精神薬理 第22巻01号',
    subTitle: '《今月の特集：気分障害の認知機能障害》',
    moreLink: 'http://www.seiwa-pb.co.jp/search/bo01/bo0103/bn/22/01index.html',
  },
  {
    title: '精神科(心療内科)第36巻第3号',
    subTitle: '特集 I ．認知リハビリテーションの可能性',
    moreLink: 'https://www.kahyo.com/product/detail/SE202003',
  },
  {
    title: '精神科(心療内科)第34巻第3号',
    subTitle:
      ' 特集 I ．精神疾患における認知機能障害 社会機能的転帰との関連、評価、治療法について',
    moreLink: 'https://www.kahyo.com/product/detail/SE201903',
  },
]

const BookSectionItem = ({ book }: { book: Book }) => {
  return (
    <li className={styles.listItem}>
      <div className={styles['wrap']}>
        <div className={styles.row}>
          {book.isNew && (
            <div className={styles.newTag}>
              <img src={newTag} alt="" />
            </div>
          )}
          <h3>{book.title}</h3>
          {book.author ? (
            <span className={styles.publish}>著者：{book.author}</span>
          ) : null}
          {book.editor ? (
            <span className={styles.publish}>編集：{book.editor}</span>
          ) : null}
          <span className={styles.publish}>出版：{book.publish}</span>
        </div>
        <div className={styles.dtailsWrap}>
          <a
            href={book.moreLink}
            target="_blank"
            rel="noreferrer"
            className={clsx(styles.urlIcon, styles.moreDetails)}
          >
            <p>詳細を見る</p>
            <img src={linkOutIcon} />
          </a>
        </div>
      </div>
      <p className={styles.description}>{book.description}</p>
      <a
        href={book.moreLink}
        target="_blank"
        rel="noreferrer"
        className={clsx(styles.urlIcon, styles.moreDetailsSp)}
      >
        <p>詳細を見る</p>
        <img src={linkOutIcon} />
      </a>
    </li>
  )
}

const DocumentSectionItem = ({ document }: { document: Document }) => {
  return (
    <li className={styles.listItem}>
      <div className={styles['documentWrap']}>
        <div className={styles.documentRow}>
          <h3>{document.title}</h3>
          <p className={styles.subTitle}>{document.subTitle}</p>
        </div>
        <a
          href={document.moreLink}
          target="_blank"
          rel="noreferrer"
          className={styles.urlIcon}
        >
          <p>詳細を見る</p>
          <img src={linkOutIcon} />
        </a>
      </div>
    </li>
  )
}

export const BookSection: React.FC = () => {
  const DEFAULT_VISIBLE_RANGE = 3
  const [visibleRange, setVisibleRange] = React.useState(DEFAULT_VISIBLE_RANGE)
  return (
    <>
      {books.slice(0, visibleRange).map((book, index) => (
        // @ts-ignore
        <BookSectionItem book={book} key={index} />
      ))}
      {visibleRange === DEFAULT_VISIBLE_RANGE ? (
        <button
          onClick={() => setVisibleRange(books.length)}
          className={styles.readmore}
        >
          <p>もっと見る</p>
          <img src={readMoreIcon} />
        </button>
      ) : null}
    </>
  )
}

export const DocumentSection: React.FC = () => {
  const DEFAULT_VISIBLE_RANGE = 3
  const [visibleRange, setVisibleRange] = React.useState(DEFAULT_VISIBLE_RANGE)
  return (
    <>
      {documents.slice(0, visibleRange).map((document, index) => (
        <DocumentSectionItem document={document} key={index} />
      ))}
      {visibleRange === DEFAULT_VISIBLE_RANGE ? (
        <button
          onClick={() => setVisibleRange(documents.length)}
          className={styles.readmore}
        >
          <p>もっと見る</p>
          <img src={readMoreIcon} />
        </button>
      ) : null}
    </>
  )
}

export const LetterSection: React.FC = () => {
  return (
    <div className={styles.letterWrap} id="books">
      <div className={styles.letter}>
        <div className={styles.headImage}>
          <img src={letterStamp} className={styles.letterStamp} />
          <img src={Border} className={styles.bookBorder} />
        </div>
        <img src={iconBooks} className={styles.bookIcon} />
        <ul className={styles.list}>
          <BookSection />
        </ul>
        <div className={styles.documents} id="documents">
          <img src={Border} className={styles.documentBorder} />
          <img src={iconReports} className={styles.documentIcon} />
          <ul className={styles.list}>
            <DocumentSection />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SectionTitle
