import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import './the-caroucel.scss'
import { Pagination, Navigation, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

export const TheCarousel: React.FC = () => {
  return (
    <div className="wrap">
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={110}
        centeredSlides
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Navigation, Pagination, Autoplay]}
        className="campaign-slider"
      >
        <SwiperSlide className="content">
          <Link to="/about">
            <StaticImage src="../../images/slide-image/launch.png" alt="" />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="content">
          <Link to="/video/4">
            <StaticImage
              src="../../images/slide-image/module-04.png"
              alt="双極I型障害における認知障害"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="content">
          <Link to="/video/6">
            <StaticImage
              src="../../images/slide-image/module-06.png"
              alt="双極II型障害における認知機能"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="content">
          <a
            href="https://www.hanmoto.com/bd/isbn/9784762827679"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage src="../../images/slide-image/book-3.png" alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide className="content">
          <Link to="/#books">
            <StaticImage
              src="../../images/slide-image/cogcafe-letter-slide.png"
              alt=""
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="content">
          <Link to="/about">
            <StaticImage
              src="../../images/slide-image/about-cognition-cafe.png"
              alt=""
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="content">
          <Link to="/">
            <StaticImage
              src="../../images/slide-image/slide-brand-01.png"
              alt=""
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="content">
          <a
            href="https://www.hanmoto.com/bd/isbn/9784344911963"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage src="../../images/slide-image/book-1.png" alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide className="content">
          <a
            href="https://www.hanmoto.com/bd/isbn/9784791110957"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage src="../../images/slide-image/book-2.png" alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide className="content">
          <a
            href="https://questant.jp/q/AD2V217R"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage src="../../images/slide-image/survey.png" alt="" />
          </a>
        </SwiperSlide>
        <SwiperSlide className="content">
          <Link to="/grandMenu">
            <StaticImage src="../../images/slide-image/grand-menu.png" alt="" />
          </Link>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}
