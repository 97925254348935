import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import styles from './kv-section.module.scss'
import rightArrow from '@/images/arrow-right.svg'

const quizList = [
  {
    id: 7,
    body: '洗濯をしながら料理など、1つは出来ても2つの行動となると出来ない そんな経験ありませんか？',
  },
  {
    id: 8,
    body: '自動販売機や駅の自動改札機、銀行のATMなどを前にしたとき、どうしたら良いかわからなくなった経験、ありませんか？',
  },
  {
    id: 10,
    body: 'Aさんに「寒いから気をつけてね」と言っても言葉の意味が分かっていない様子だったので「寒いから風邪ひかないように上着を着ましょう」と言い直したところ理解が出来た様子。Aさんに想定される中核症状は？',
  },
  {
    id: 15,
    body: '同じ職場のBさん、どうも無関心で活動力や自発性が欠けているように見えてしまう こんな経験ありませんか？',
  },
]

const displayQuiz = quizList[Math.floor(Math.random() * quizList.length)]

const KvSection: React.FC = () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.kvImage}>
        <div className={styles.kvImagePc}>
          <StaticImage
            src="../../images/kv-01.png"
            width={1094}
            height={880}
            quality={90}
            formats={['auto', 'webp', 'avif']}
            alt="認知機能クイズ"
            loading="eager"
          />
        </div>
        <div className={styles.kvImageSp}>
          <StaticImage
            src="../../images/kv-sp-02.png"
            width={833}
            height={498}
            quality={100}
            formats={['auto', 'webp', 'avif']}
            alt="認知機能クイズ"
            loading="eager"
          />
        </div>
      </div>
      <div className={styles.quiz}>
        <div className={styles.quizMoment}>
          <StaticImage
            src="../../images/quiz.png"
            width={850}
            height={650}
            quality={60}
            formats={['auto', 'webp', 'avif']}
            alt="認知機能クイズ"
            loading="eager"
          />
          {displayQuiz && (
            <>
              <div className={styles.quizBody}>
                <span className={styles.quizHead}>
                  日常診療でこういうケースを目にしたことはありますか？
                </span>
                <p>{displayQuiz.body}</p>
              </div>
              <Link to={`/quiz/${displayQuiz.id}`}>
                <button className={styles.answerButton}>
                  <span>答えを見る</span>
                  <img src={rightArrow} />
                </button>
              </Link>
            </>
          )}
        </div>

        <div className={styles.quizMomentSp}>
          <StaticImage
            src="../../images/quiz-sp.png"
            width={690}
            height={480}
            quality={60}
            formats={['auto', 'webp', 'avif']}
            alt="認知機能クイズ"
            loading="eager"
          />
          {displayQuiz && (
            <>
              <div className={styles.quizBody}>
                <span className={styles.quizHead}>
                  日常診療でこういうケースを目にしたことはありますか？
                </span>
                <p>{displayQuiz.body}</p>
              </div>
              <Link to={`/quiz/${displayQuiz.id}`}>
                <button className={styles.answerButton}>
                  <span>答えを見る</span>
                  <svg
                    width="13"
                    height="11"
                    viewBox="0 0 13 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.125 1.7085L11.5 5.50016L7.125 9.29183M1.5 5.50016H11.5H1.5Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default KvSection
