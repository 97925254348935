import { Link } from 'gatsby'
import * as React from 'react'
import { CardItemProps, ExpCard } from './exp-card'
import styles from './exp-movies-section.module.scss'
import SectionTitle from './section-title'
import { thumbList } from '@/data/moc'

const ExpMoviesSection: React.FC = () => (
  <div className={styles.wrap}>
    <div className={styles.expInner}>
      <SectionTitle theme="light">
        <Link to="/video">Cognition Cafe Reserve</Link>
      </SectionTitle>
      <p className={styles.sectionIntro}>
        &rdquo;Reserve&rdquo;は、認知機能に関するマエストロを目指してもらえるように
        <br />
        より専門的なコンテンツを紹介していきます。
      </p>
      <div className={styles.cardList}>
        {thumbList.map((item, index) => (
          <ExpCard key={index} cardItem={item as CardItemProps} />
        ))}
      </div>
    </div>
  </div>
)

export default ExpMoviesSection
